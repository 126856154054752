<template>
  <div class="mx-3">
    <h2 class="px-5 py-5">{{ $t('Materials Inventory Report') }}</h2>
    <v-row class="px-10 py-1">
      <v-col md="3">
        <AutoCompleteField :label="$t('Material Name')" endPoint="/inventories/materials/auto-complete"
          v-model="filters.material_name" />
      </v-col>
      <v-col md="3">
        <AutoCompleteField :label="$t('folder name')" endPoint="/inventories/folders/auto-complete"
          v-model="filters.folder" />
      </v-col>
      <v-col md="3">
        <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.from-date')" type="date" v-model="filters.from">
        </v-text-field>
      </v-col>
      <v-col md="3">
        <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.to-date')" type="date" v-model="filters.to">
        </v-text-field>
      </v-col>
      <v-col md="3">
        <AutoCompleteField :label="$t('Client Account')" endPoint="/accounting/account/auto-complete"
          v-model="filters.client_account" />
      </v-col>
      <v-col md="3">
        <AutoCompleteField :label="$t('cost center')" endPoint="/accounting/cost-center/auto-complete"
          v-model="filters.cost_center" />
      </v-col>
      <v-col md="3">
        <AutoCompleteField :label="$t('term')" endPoint="/terms" v-model="filters.term" />
      </v-col>
      <v-col md="3">
        <v-select :dark="$store.state.isDarkMode" :items="itemsInvoece" :label="$t('Invoice Name')" multiple
          item-text="name" item-value="id" v-model="filters.invoice_name"></v-select>
      </v-col>
      <v-col md="3">
        <v-select :dark="$store.state.isDarkMode" v-model="filters.material_type" :items="itemMaterialType"
          :label="$t('Material Type')" item-text="name" item-value="id"></v-select>
      </v-col>
      <v-col md="3">
        <AutoCompleteField :label="$t('Beneficiary')" endPoint="/accounting/account/auto-complete"
          v-model="filters.beneficiary" />

      </v-col>
      <v-col md="3">
        <AutoCompleteField :label="$t('Inventory')" endPoint="/inventories/auto-complete"
          v-model="filters.inventory_name" />
      </v-col>
      <v-col md="3">
        <v-row>
          <v-checkbox :dark="$store.state.isDarkMode" :label="$t('Empty')" class="mx-4 mb-4" v-model="filters.not_empty"
            hide-details></v-checkbox>
          <v-checkbox :dark="$store.state.isDarkMode" :label="$t('Equal')" class="mx-4 mb-4" v-model="filters.equal"
            hide-details></v-checkbox>
        </v-row>
      </v-col>
      <v-col md="3" class="mb-3 py-0">
        <v-btn color="green" dark block @click="getAll(1)">{{ $t('search') }}</v-btn>
      </v-col>
      <v-col md="3" class="mb-3 py-0">
        <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
      </v-col>
      <v-col md="3" class="mb-3 py-0">
        <ExportExcel end-point="/inventories/materials/index/export" :params="filters" />
      </v-col>
    </v-row>
    <v-data-table :dark="$store.state.isDarkMode" :headers="headers" :loading="loading" :items="tableItems"
      class="elevation-1 px-10" hide-default-footer :items-per-page="10">
    </v-data-table>
    <v-pagination v-if="!loading && tableItems.length" @input="getAll" v-model="page"
      :length="Math.ceil(total / 10)"></v-pagination>
  </div>
</template>
  
<script>
import axios from "axios";
import i18n from '@/i18n';
import AutoCompleteField from "../../../components/core/AutoCompleteField.vue";
import ExportExcel from "../../../components/core/ExportExcel.vue";

export default {
  components: { AutoCompleteField, ExportExcel, },
  data: () => ({
    tableItems: [],
    loading: false,
    page: 1,
    total: 1,
    headers: [
      { text: i18n.t("Material Code"), value: "serial" },
      { text: i18n.t('Material Name'), value: "material_name" },
      { text: i18n.t('Father Folder'), value: "parent" },
      { text: i18n.t('Input Quantity'), value: "in" },
      { text: i18n.t('Output Quantity'), value: "out" },
      { text: i18n.t('balance'), value: "balance" },
      { text: i18n.t('Cost'), value: "cost" },
      { text: i18n.t('Total Cost'), value: "total_cost" },
    ],
    itemsInvoece: [],
    itemMaterialType: [],
    filters: {
      material_name: null,
      not_empty: null,
      equal: null,
      folder: null,
      from: null,
      to: null,
      invoice_name: [],
      client_account: null,
      material_type: null,
      inventory_name: null,
      beneficiary: null,
      cost_center: null,
      term: null,
    },
  }),
  methods: {
    clearFilter() {
      this.filters.client_account = undefined;
      this.filters.folder = undefined;
      this.filters.cost_center = undefined;
      this.filters.from = undefined;
      this.filters.to = undefined;
      this.filters.term = undefined;
      this.filters.material_name = undefined;
      this.filters.not_empty = undefined;
      this.filters.equal = undefined;
      this.filters.inventory_name = undefined;
      this.filters.invoice_name = undefined;
      this.filters.material_type = undefined;
      this.filters.beneficiary = undefined;
      this.page = 1;
      this.getAll();
    },

    async getAll(page) {
      try {
        if (!page) this.page = 1;
        const filterTemp = {};
        Object.keys(this.filters).forEach((key) => {
          if (this.filters[key]) filterTemp[key] = this.filters[key];
        });
        this.loading = true;
        const res = await axios.get("inventories/materials/index", {
          params: {
            size: 10,
            page: this.page,
            ...filterTemp,
          },
        });
        const arr = Array.from(res.data.data)
        if (Array.isArray(arr)) {
          this.tableItems = arr.map((el) => {
            return {...el,};
          });
        }
        this.total = res.data.meta.total;
      } finally {
        this.loading = false;
      }
    },
    async getInvoiceTypes() {
      const res = await axios.get("/inventories/invoice-types/index");
      this.itemsInvoece = res.data.data.map((el) => ({ ...el, }));
    },
    async getallType() {
      const res = await axios.get("/inventories/material-types/value/index");
      this.itemMaterialType = res.data.data;
    }
  },
  created() {
    this.getallType();
    this.getAll();
    this.getInvoiceTypes();
  },
};
</script>
  
<style></style>
  